import request from '@/utils/request'

export function linkList(params) {
    return request.get('/diary/message/link/list', { params })
}
export function messagePage(params) {
    return request.get('/diary/message/mess/page', { params })
}
export function clearUnread(params) {
    return request.get('/diary/message/link/clearUnread', { params })
}
export function deleteMessage(params) {
    return request.get('/diary/message/mess/delete', { params })
}