/* eslint-disable no-bitwise */
export function generateUUID(length, withHyphen) {
    const chars = '0123456789abcdef'
    let randomBytes = new Uint8Array(16)
    crypto.getRandomValues(randomBytes)
    randomBytes[6] &= 0x0f
    randomBytes[6] |= 0x40
    randomBytes[8] &= 0x3f
    randomBytes[8] |= 0x80
    let uuid = ''
    for (let i = 0; i < 16; i++) {
        uuid += chars[randomBytes[i] >>> 4]
        uuid += chars[randomBytes[i] & 0x0f]
    }
    if (withHyphen) {
        uuid = uuid.slice(0, 8) + '-' + uuid.slice(8, 12) + '-' + uuid.slice(12, 16) + '-' + uuid.slice(16, 20) + '-' + uuid.slice(20)
    }
    return uuid.slice(0, Math.min(length, uuid.length))
}