<template>
    <Tooltip placement="bottom" trigger="click">
      <Icon type="ios-more" size="20" class="more-icon" />
      <div slot="content" class="tooltip-content">
        <div v-for="action in actions" :key="action.name" class="tooltip-item">
          <Button @click="handleAction(action)">
            <Icon :type="action.icon" size="16" /> {{ action.name }}
          </Button>
        </div>
      </div>
    </Tooltip>
  </template>

<script>
import { Tooltip, Icon, Button } from 'view-design'

export default {
    components: {
        Tooltip,
        Icon,
        Button,
    },
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        handleAction(action) {
            if (action.callback) {
                action.callback()
            }
        },
    },
}
</script>

  <style>
  .more-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* 图标宽度 */
  height: 30px; /* 图标高度 */
  border-radius: 50%; /* 圆形边框 */
  border: 1px solid #c0c4cc; /* 边框颜色 */
  background-color: #b4cde6; /* 背景颜色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* 阴影 */
  cursor: pointer;
  transition: all 0.3s;
}

.more-icon:hover {
  border-color: #409eff; /* 悬浮时边框颜色 */
  background-color: #ecf5ff; /* 悬浮时背景颜色 */
}

  .tooltip-content {
    background-color: #ffffff; /* 白色背景 */
    padding: 10px; /* 内边距 */
    border-radius: 6px; /* 圆角 */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* 阴影 */
  }

  .tooltip-item {
    margin: 10px 0; /* 增加上下间距 */
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .tooltip-item a {
    display: flex;
    align-items: center;
    color: #5c6b77; /* 文字颜色 */
    font-size: 14px; /* 字体大小 */
  }

  .tooltip-item a Icon {
    margin-right: 8px; /* 图标和文本间距 */
  }

  .tooltip-item:hover {
    background-color: #f5f5f5; /* 悬浮背景颜色 */
  }
  </style>
