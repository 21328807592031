<template>
  <div class="input-group">
    <div class="action-buttons">
      <Upload
        :headers="headers"
        :before-upload="beforeUpload"
        action="https://www.bjca.xyz/diary/message/upload"
        :on-success="sendImgAndVideo"
        :on-error="handleUploadError"
        :show-upload-list="false"
         accept="image/*,video/*"
    :format="['jpg', 'jpeg', 'png', 'gif', 'mp4', 'avi', 'mov']"
      >
        <iButton icon="ios-images" class="media-button"></iButton>
      </Upload>

      <Upload
        :headers="headers"
        :before-upload="beforeUploadFile"
        action="https://www.bjca.xyz/diary/message/upload"
        :on-success="sendFile"
        :on-error="handleUploadError"
        :show-upload-list="false"
      >
        <iButton icon="ios-folder" class="file-button"></iButton>
      </Upload>

      <iButton
        :class="{ 'audio-button': true, recording: isRecording }"
        @click="toggleRecording"
      >
        <span v-if="isRecording">{{ recordingTime }}</span>
        <span v-else>开始录音</span>
      </iButton>

      <Poptip
        title
        trigger="click"
        placement="bottom"
        ref="emojiPicker"
        @on-popper-hide="handleEmojiPickerHide"
      >
        <iButton
          icon="md-happy"
          @click="toggleEmojiPicker"
          class="emoji-button"
        ></iButton>
        <div slot="content" class="emoji-content">
          <Tabs v-model="activeTab">
            <TabPane label="普通表情">
              <div
                v-for="(emoji, index) in normalEmojis"
                :key="`emoji-${index}`"
                @click="insertEmoji('normal', emoji)"
              >
                {{ emoji }}
              </div>
            </TabPane>
            <TabPane label="图片表情">
              <Input
                placeholder="搜索表情..."
                v-model="searchText"
                @on-change="getEmoji(searchText)"
              />
              <div v-for="img in emojiImgList" :key="img">
                <img
                  style="width: 120px; height: 120px"
                  :src="img"
                  @click="insertEmoji('img', img)"
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Poptip>
    </div>

    <div class="message-input-container">
      <Input
        v-model="newMessage"
        placeholder="请输入消息..."
        @on-enter="send"
        type="textarea"
        class="message-input"
      />
      <iButton @click="send" icon="ios-send" class="send-button"></iButton>
    </div>

    <Modal
      v-model="showRecordingModal"
      title="录音"
      @on-ok="sendAudio"
      @on-cancel="cancelRecording"
    >
      <div class="recording-modal-content">
        <div class="recording-timer">{{ recordingTime }}</div>
        <div class="recording-controls">
          <iButton
            v-if="!isRecording"
            class="record-button"
            @click="startRecording"
          >
            开始录音
          </iButton>
          <iButton
            v-if="isRecording"
            class="stop-button"
            @click="stopRecording"
          >
            停止录音
          </iButton>
        </div>
        <audio v-if="audioBlob" controls :src="audioUrl"></audio>
      </div>
    </Modal>
  </div>
</template>
<script>
import { generateUUID } from "@/utils/uuidGenerator.js";
import axios from "axios";

export default {
  data() {
    return {
      mediaType: "",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      newMessage: "",
      fileId: "",
      activeTab: "1",
      normalEmojis: [
        "😀",
        "😁",
        "😂",
        "🤣",
        "😃",
        "😄",
        "😅",
        "😆",
        "😉",
        "😊",
        "😋",
        "😎",
        "😍",
        "😘",
        "🥰",
        "😗",
        "😙",
        "😚",
        "🙂",
        "🤗",
        "🤩",
        "🤔",
        "🤨",
        "😐",
        "😑",
        "😶",
        "😏",
        "😒",
        "🙄",
        "😬",
        "🤥",
        "😌",
        "😔",
        "😪",
        "🤤",
        "😴",
        "😷",
        "🤒",
        "🤕",
        "🤢",
        "🤮",
        "🤧",
        "😇",
        "🤠",
        "🤡",
        "🥳",
        "🥺",
        "🤥",
        "🤫",
        "🤭",
        "🧐",
        "🤓",
        "😈",
        "👿",
        "👹",
        "👺",
        "💀",
        "👻",
        "👽",
        "🤖",
        "💩",
        "😺",
        "😸",
        "😹",
        "😻",
        "😼",
        "😽",
        "🙀",
        "😿",
        "😾",
      ],
      searchText: "",
      emojiImgList: [],
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      recordingTime: "00:00",
      recordingInterval: null,
      showRecordingModal: false,
      audioBlob: null,
      audioUrl: "",
    };
  },
  watch: {
    newMessage(val) {
      if (val.length === 0) {
        this.emojiImgList = [];
      }
      if (val.length > 0) {
        this.getEmoji(val);
      }
    },
  },
  mounted() {
    document.addEventListener("paste", this.handlePaste);
  },
  methods: {
    handleEmojiPickerHide() {
      this.activeTab = "1";
    },
    toggleEmojiPicker(event) {
      event.stopPropagation();
      this.$refs.emojiPicker.visible = !this.$refs.emojiPicker.visible;
    },
    insertEmoji(type, emoji) {
      if (type === "normal") {
        this.newMessage += emoji;
      } else if (type === "img") {
        const fileTempId = generateUUID(32, false);
        this.$emit("sendMedia", emoji, "img", fileTempId, "true");
        this.$emit("sendMedia", emoji, "img", fileTempId, "false");
      }
      this.toggleEmojiPicker();
    },
    uploadImage(file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post("https://www.bjca.xyz/diary/file/uploadImg", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.$emit(
        "sendMedia",
        response.data.retVal,
        "img",
        this.fileId,
        "false"
      );
          this.sendImage(response.data, file, []);
        })
        .catch((error) => {
          this.handleUploadError(error, file, []);
        });
    },
    handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;

      for (const item of items) {
        if (item.type.indexOf("image") !== -1) {
          const file = item.getAsFile();
          this.handlePastedImage(file);
          this.uploadImage(file);
        }
      }
    },
    handlePastedImage(file) {
      const tempUrl = URL.createObjectURL(file);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "img", this.fileId, "true");
    },
    getEmoji(text) {
      this.emojiImgList = [];
      const service1 = axios.create({
        baseURL: "https://www.bjca.xyz/emojie/search/" + text,
        timeout: 60000,
      });
      service1
        .get()
        .then((res) => {
          this.emojiImgList = res.data.images;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeUpload(file) {
      const allowedImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/svg+xml",
      ];
      const allowedVideoTypes = [
        "video/mp4",
        "video/avi",
        "video/mov",
        "video/mpeg",
        "video/webm",
      ];
      if (
        !allowedImageTypes.includes(file.type) &&
        !allowedVideoTypes.includes(file.type)
      ) {
        this.$Message.error("暂时只支持发送图片和视频");
        return false;
      }
      const tempUrl = URL.createObjectURL(file);
      if (file.type.includes("image")) {
        this.mediaType = "img";
        const fileTempId = generateUUID(32, false);
        this.fileId = fileTempId;
        this.$emit("sendMedia", tempUrl, "img", this.fileId, "true");
      } else if (file.type.includes("video")) {
        this.mediaType = "video";
        const fileTempId = generateUUID(32, false);
        this.fileId = fileTempId;
        this.$emit("sendMedia", tempUrl, "video", this.fileId, "true");
      }
      return true;
    },
    beforeUploadFile(file) {
      const tempUrl = URL.createObjectURL(file);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "file", this.fileId, "true");
      this.$emit("sendFileName", this.fileId, file.name);
      return true;
    },
    sendImgAndVideo(response, file, fileList) {
      this.$emit(
        "sendMedia",
        response.retVal,
        this.mediaType,
        this.fileId,
        "false"
      );
    },
    sendFile(response, file, fileList) {
      this.$emit(
        "sendMedia",
        response.retVal,
        "file",
        this.fileId,
        "false"
      );
    },
    handleUploadError(error, file, fileList) {
      console.error("上传文件失败", error);
    },
    send() {
      if (this.newMessage.trim()) {
        console.log('发送消息',this.newMessage);
        this.$emit("send", this.newMessage);
        this.newMessage = "";
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        if (this.audioBlob) {
          this.$Message.warning("当前有未完成的录音，请先处理");
          return;
        }
        this.startRecording();
      }
    },
    startRecording() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.ondataavailable = (event) => {
              this.recordedChunks.push(event.data);
            };
            this.mediaRecorder.onstop = this.handleRecordingStop;
            this.mediaRecorder.start();
            this.isRecording = true;
            this.$nextTick(() => {
              // 在视图更新后展示录音模态框
              this.showRecordingModal = true;
            });
            this.startTimer();
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
      this.isRecording = false;
      this.stopTimer();
    },
    handleRecordingStop() {
      this.audioBlob = new Blob(this.recordedChunks, { type: "audio/webm" });
      this.audioUrl = URL.createObjectURL(this.audioBlob);
      this.recordedChunks = [];
      this.showRecordingModal = true;
    },
    sendAudio() {
      if (!this.audioBlob) return;

      const tempUrl = URL.createObjectURL(this.audioBlob);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "audio", this.fileId, "true");

      const formData = new FormData();
      formData.append("file", this.audioBlob, "recording.webm");
      axios
        .post("https://www.bjca.xyz/diary/message/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.sendAudioResponse(response.data);
        })
        .catch((error) => {
          this.handleUploadError(error, this.audioBlob, []);
        });

      this.showRecordingModal = false;
      this.audioBlob = null;
    },
    sendAudioResponse(response) {
      if (response.code === 0) {
        const fileUrl = response.retVal;
        this.$emit("sendMedia", fileUrl, "audio", this.fileId, "false");
      }
    },
    cancelRecording() {
      this.showRecordingModal = false;
      this.audioBlob = null;
    },
    startTimer() {
      let seconds = 0;
      this.recordingInterval = setInterval(() => {
        seconds += 1;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.recordingTime = `${minutes
          .toString()
          .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.recordingInterval);
      this.recordingTime = "00:00";
    },
  },
};
</script>
<style scoped>
.input-group {
  display: flex;
  align-items: center;
  padding: 8px;
}

.input-group .ivu-input {
  flex-grow: 1;
  margin-right: 8px;
  min-height: 40px;
  max-height: 100px;
  overflow-y: auto;
}

.action-button,
.send-button {
  padding: 6px 12px;
  font-size: 16px;
  height: 40px;
  line-height: 1;
  margin-left: 5px;
}

.action-buttons {
  display: flex;
  margin-bottom: 8px;
}

.media-button,
.emoji-button,
.audio-button,
.file-button {
  padding: 8px;
  margin-right: 5px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
}

.audio-button {
  background-color: #3498db;
}

.message-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.message-input {
  flex-grow: 1;
  min-height: 40px;
  max-height: 100px;
  overflow-y: auto;
}

.send-button {
  padding: 12px 25px;
  font-size: 20px;
  background-color: #2ecc71;
  color: white;
  margin-left: 8px;
  border-radius: 25px;
  min-width: 60px;
}

.send-button:hover {
  background-color: #27ae60;
}

.emoji-content {
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.ivu-tabs .ivu-tabs-bar {
  margin-bottom: 0;
}

.emoji-content > .ivu-tabs .ivu-tabs-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 10px;
}

.emoji-content > .ivu-tabs .ivu-tabs-content > div {
  padding: 5px;
  cursor: pointer;
}

.recording-modal-content {
  text-align: center;
}

.recording-timer {
  font-size: 24px;
  margin-bottom: 16px;
}

.recording-controls {
  margin-bottom: 16px;
}

.record-button,
.stop-button,
.send-button,
.cancel-button {
  margin: 0 8px;
}

.record-button {
  background-color: #e74c3c;
}

.stop-button {
  background-color: #3498db;
}

.send-button {
  background-color: #2ecc71;
}

.cancel-button {
  background-color: #e74c3c;
}

.send-button:hover {
  background-color: #27ae60;
}

.cancel-button:hover {
  background-color: #c0392b;
}
</style>
